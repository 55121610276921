import PropTypes from "prop-types";

import { ReactComponent as AcceptedIcon } from "./images/accepted.svg";
import { ReactComponent as FreeIcon } from "./images/free.svg";
import { ReactComponent as HeartIcon } from "./images/heart.svg";
import { ReactComponent as PriceIcon } from "./images/price.svg";
import { ReactComponent as RestrictionsIcon } from "./images/restrictions.svg";
import { ReactComponent as ConfidentialIcon } from "./images/confidential.svg";
import { ReactComponent as MedicationsIcon } from "./images/medications.svg";

const ICONS = {
    accepted: AcceptedIcon,
    free: FreeIcon,
    heart: HeartIcon,
    price: PriceIcon,
    restrictions: RestrictionsIcon,
    confidential: ConfidentialIcon,
    medications: MedicationsIcon,
};
function ContentRxIcon({ className, iconColor, imageName }) {
    const IconComponent = ICONS[imageName]; // Select the appropriate icon component

    return (
        <div className={`component__content_rx_icon ${className}`}>
            <IconComponent color={iconColor} />
        </div>
    );

    // return (
    //     <img
    //         className={`component__content_rx_icon ${className}`}
    //         alt={imageAlt}
    //         src={require(`./images/${imageName}.svg`)}
    //     />
    // );
}

ContentRxIcon.propTypes = {
    className: PropTypes.string,
    imageAlt: PropTypes.string,
    iconColor: PropTypes.string,
    imageName: PropTypes.oneOf([
        "accepted",
        "free",
        "heart",
        "price",
        "restrictions",
        "confidential",
        "medications",
    ]),
};
ContentRxIcon.defaultProps = {
    imageAlt: "CharityRx icon",
    iconColor: "#921fa8",
};

export default ContentRxIcon;
