import { findIndex, get, isEmpty, minBy } from "lodash";
import { makeAutoObservable, action, toJS, reaction } from "mobx";

const formSelectData = (obj) =>
    Object.keys(obj).map((item) => ({ label: item, value: item }));

export default class PackagesStore {
    resultList = [];

    current = null;
    default = null;

    customUnit = null;

    state = "pending"; // "pending", "done" or "error"

    constructor(root) {
        makeAutoObservable(this);

        this.root = root;

        this.updateCurrentPackage = reaction(
            () => this.current,
            () => {
                this.initSearch();
            }
        );
    }

    get packages() {
        return toJS(this.resultList);
    }

    get options() {
        if (this.packages !== null) {
            return formSelectData(this.packages);
        } else {
            return [];
        }
    }

    get selected() {
        if (this.customUnit === null) {
            if (this.options[this.current] !== undefined) {
                return this.options[this.current];
            } else {
                return null;
            }
        } else {
            // const regex = /^\d/gm;
            // We need to check if customUnit is a 1 or more
            const isMoreThanOne = +this.customUnit > 1;

            // Find the postfix with value more than one
            const firstOpiton = this.options[0];
            const isFirstOptionMoreThanOne = +firstOpiton.value > 1;

            const label = firstOpiton.label.split(" ")[1];

            const postfix =
                isMoreThanOne && isFirstOptionMoreThanOne
                    ? label
                    : isFirstOptionMoreThanOne
                    ? label.slice(0, -1)
                    : label + (label[label.length - 1] === "s" ? "" : "s");

            return {
                value: this.customUnit,
                label: `${this.customUnit} ${
                    +this.customUnit === 1 ? postfix.slice(0, -1) : postfix
                }`,
            };
        }
    }

    setCustomUnit(value) {
        this.customUnit = value;
        this.initSearch();
    }

    selectPackage(newItemValue) {
        let newItemKey = findIndex(this.options, ["value", newItemValue]);
        if (newItemKey !== this.current) {
            this.current = newItemKey;
            this.customUnit = null;
        }
    }

    update(ndcs) {
        this.reset();
        this.root.resultStore.reset();
        this.root.service.getPackagesByNdcs(ndcs).then(
            action("fetchSuccess", (result) => {
                if (result) {
                    this.set(result);
                    this.state = "done";
                } else {
                    this.state = "error";
                }
            }),
            action("fetchError", () => {
                this.state = "error";
            })
        );
    }

    set(data) {
        this.resultList = data.packages;
        this.default = data.default;
        if (!isEmpty(this.default)) {
            this.selectPackage(this.default);
        } else {
            if (!isEmpty(this.options)) {
                this.selectPackage(this.options[0].value);
            }
        }
    }

    initSearch() {
        const item = this.getSelectedItem();
        if (item !== null) {
            this.root.resultStore.search(item.ndcs, item.unit);
        }
    }

    getSelectedItem() {
        const { packages, selected, customUnit } = this;

        if (customUnit !== null) {
            const packItem = Object.values(packages);

            // Find the item closest to customUnit
            const closest = minBy(
                packItem,
                ({ unit }) =>
                    Math.abs(parseInt(unit, 10) - parseInt(customUnit)) // Compare absolute difference
            );

            if (closest) {
                return {
                    ndcs: closest.ndcs,
                    unit: customUnit,
                };
            }
        }

        if (selected && selected.value) {
            return get(packages, selected.value);
        }

        return null;
    }

    dispose() {
        // So, to avoid subtle memory issues, always call the
        // disposers when the reactions are no longer needed.
        this.updateCurrentPackage();
    }

    reset() {
        this.state = "pending";

        this.resultList = [];
        this.current = null;
        this.default = null;
        this.customUnit = null;
    }
}
